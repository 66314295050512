import { useEffect, useState, useRef } from "react";
import { Col } from "../components/layout";

const INTERVAL_PER_SECOND = 1000 / 60;
const EXPIRE_DATE = new Date('2022-12-24T00:00:00');

function formatNumber(number, desiredLength) {
  let str = String(number);
  if (str.length > desiredLength) {
    return str.slice(0, desiredLength);
  } else {
    return str.padStart(desiredLength, "0");
  }
}

function Stopwatch({ expired, setExpired }) {
  const [initialStart, setInitialStart] = useState(null);
  const [timerText, setTimerText] = useState("00:00:000");
  const timerIdRef = useRef(null);

  useEffect(() => {
    setInitialStart(0);
  }, []);

  useEffect(() => {
    if (initialStart !== null) {
      timerIdRef.current = setInterval(() => {
        updateTimer();
      }, INTERVAL_PER_SECOND);
    }
  }, [initialStart]);

  const updateTimer = () => {
    const secondsLeft =
      (EXPIRE_DATE.getTime() - new Date().getTime()) / 1000;
    const minsLeft = Math.floor(secondsLeft / 60) % 60;
    const hoursLeft = Math.floor(secondsLeft / 60 / 60) % 24;
    const daysLeft = Math.floor(secondsLeft / 60 / 60 / 24);

    const secondsText = formatNumber(Math.floor(secondsLeft) % 60, 2);
    const minsText = formatNumber(minsLeft, 2);
    const hoursText = formatNumber(hoursLeft, 2);
    const daysText = formatNumber(daysLeft, 2);

    setTimerText(
      `(expires in ${daysText}d:${hoursText}h:${minsText}m:${secondsText}s, Christmas Eve)`
    );

    if (secondsLeft < 0) {
      setExpired(true);
    }
  };

  return (
    <Col>
      {!expired && (
        <h2 style={{ color: "white", fontFamily: "monospace" }}>{timerText}</h2>
      )}
    </Col>
  );
}

export default Stopwatch;
