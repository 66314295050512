import { useState } from "react";
import styled from "styled-components";
import { Col, Row } from "./components/layout";
import Stopwatch from "./components/Stopwatch";
//@ts-ignore
import AxcelerateLastLunch from "./img/aXcelerate_last_lunch.JPG";
//@ts-ignore
import ExpiredCode from "./img/expired_scott.png";
interface HomeProps {}
const Home = (props: HomeProps) => {
  const [expired, setExpired] = useState(false);
  return (
    <Container>
      <Row style={{ width: "100%", justifyContent: "flex-start" }}>
        <h1 style={{ margin: 20 }}>Jeremy is Awesome</h1>
      </Row>
      <Row className="image-box">
        <img src={AxcelerateLastLunch} />
      </Row>
      <Row
        style={{ width: "100%", justifyContent: "flex-end", marginRight: 20 }}
      >
        <Col>
          <h1 style={{ marginTop: 20 }}>
            Scott is{" "}
            {expired ? (
              " not"
            ) : (
              <s>
                <u>not</u>
              </s>
            )}
            {expired ? "" : " Awesome too"}
          </h1>
          <Stopwatch expired={expired} setExpired={setExpired} />
          <CodeSnapshotContainer>
           {!expired && <img className="zoom" src={ExpiredCode} width={100} />}
          </CodeSnapshotContainer>
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled(Col)`
  justify-content: space-between;
  height: 100vh;
  background-color: black;
  color: white;
  h1 {
    font-size: 60px;
  }
  padding:20px;
`;

const CodeSnapshotContainer = styled.div`
  img.zoom {
    border-radius: 4px;
    transition: transform 0.2s;
  }
  img.zoom:hover {
    transform: scale(5) translate(0px, -20px);
  }
`;

export default Home;